// Pick colors on https://material.io/resources/color/#!/

export const lightPaltte = {
  primary: {
    light: '#6b91ff',
    main: '#0064ff',
    dark: '#003bcb',
    contrastText: '#fff',
  },
  secondary: {
    light: '#6cc7ff',
    main: '#1a97f5',
    dark: '#006ac2',
    contrastText: '#fff',
  },
  test: {
    // primary: '',
  },
  mybg: {
    main: '#fefefe',
    top: 'rgb(250, 251, 251)',
  },
  grey: {
    50: "#fafafa",
    100: "#f5f5f5",
    200: "#eeeeee",
    300: "#e0e0e0",
    400: "#bdbdbd",
    500: "#9e9e9e",
    600: "#757575",
    700: "#616161",
    800: "#424242",
    900: "#212121",
    A100: "#d5d5d5",
    A200: "#aaaaaa",
    A400: "#303030",
    A700: "#616161"
  },
  background: {
    paper: '#fff',
    default: 'rgb(250, 251, 251)',
  },
};

export const darkPaltte = {
  primary: {
    light: '#ff79ff',
    main: '#e040fb',
    dark: '#aa00c7',
    contrastText: '#e040fb',
  },
  secondary: {
    light: '#e040fb',
    main: '#e040fb',
    dark: '#e040fb',
    contrastText: '#fff',
  },
  mybg: {
    main: '#fffd',
    top: "rgb(219, 238, 255)",
  },

  background: {
    paper: 'rgb(40, 44, 52)',
    default: 'rgb(32, 35, 42)',
  },
}

export const shape = {
  // borderRadius: 8,
  paperBorderRadius: 8,
}