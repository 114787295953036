import * as React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import CssBaseline from '@mui/material/CssBaseline';
import useMediaQuery from '@mui/material/useMediaQuery';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';
import { useCookies } from "react-cookie"
import { lightPaltte, darkPaltte, shape } from '../../src/theme';
import { ColorModeContext } from '../../src/context';


export default function TopLayout(props) {


  const [cookies, setCookie, removeCookie] = useCookies(["colorMode"]);
  const [mode, setMode] = React.useState('light');
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)', {noSsr: true});



  

  React.useEffect(() => {
    let prevMode ;
    prevMode = prefersDarkMode ? 'dark' : 'light';
  
    if (cookies.colorMode === 'dark' || cookies.colorMode === 'light') {
      prevMode = cookies.colorMode;
    }
    if (!prevMode) prevMode = 'light'

    setMode(prevMode)
  }, [])
  

  const colorMode = React.useMemo(() => ({
    toggleColorMode: () => {
      setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
    },
  }),[],);

  const theme = React.useMemo(() => (
    createTheme({
      palette: {
        mode,
        ...(mode === 'light' ? lightPaltte : darkPaltte),
      },
      shape: {...shape}
    })
  ),[mode]);

  React.useMemo(() => (
    setCookie("colorMode", mode)
  ),[mode]);

  const themeColor = mode === 'light' ? theme.palette.primary.main : theme.palette.background.paper ;



  return (
    <ColorModeContext.Provider value={colorMode}>
      <Helmet>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
        <link
          href="https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap"
          rel="stylesheet"
        />
          {/*<!-- fonts -->*/}
        <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        {/*<!-- ニコ文字 -->*/}  <link href="https://fonts.googleapis.com/earlyaccess/nicomoji.css" rel="stylesheet" />
        {/*<!-- ニコ文字漢字(仮) -->*/} <link href="https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@700&display=swap" rel="stylesheet" />

        <meta name="theme-color" content={themeColor} />
      </Helmet>
      <ThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        {props.children}
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

TopLayout.propTypes = {
  children: PropTypes.node,
};

